import React, { Fragment } from 'react';

function RoomDetail(props) {
  return (
    <Fragment>
      <div className="border-light rounded-4 px-30 py-30 sm:px-20 sm:py-20 mt-5">
        <div className="row y-gap-20">
          <div className="col-12">
            <h3 className="text-18 fw-500 mb-15">
              {props.room.category
                ? props.room.title + ' ( ' + props.room.category + ' ) '
                : props.room.title}
            </h3>
            <div className="roomGrid">
              <div className="roomGrid__header">
                <div></div>
                <div>Specification & Amenities</div>
                {/* <div>Price</div> */}
                <div>Overview</div>
                <div></div>
              </div>

              <div className="roomGrid__grid">
                <div>
                  <div className="ratio ratio-1:1">
                    <img
                      src={
                        '/assets/img/accommodation/' +
                        props.slug +
                        '/' +
                        props.room.slug +
                        '/' +
                        props.room.image[0]
                      }
                      alt="image"
                      className="img-ratio rounded-4"
                    />
                  </div>
                  <div className="px-10 py-10 col-12 h-full">
                    <a
                      href={
                        '/assets/img/accommodation/' +
                        props.slug +
                        '/' +
                        props.room.slug +
                        '/' +
                        props.room.image[0]
                      }
                      className="button -dark-1 px-24 py-15 bg-blue-1 text-white js-gallery"
                      data-gallery={props.room.title}
                    >
                      See All Photos
                    </a>
                    {props.room.image.slice(1, 100).map((image, index) => (
                      <a
                        key={index}
                        href={
                          '/assets/img/accommodation/' +
                          props.slug +
                          '/' +
                          props.room.slug +
                          '/' +
                          image
                        }
                        className="js-gallery"
                        data-gallery={props.room.title}
                      ></a>
                    ))}
                  </div>
                </div>

                <div className="y-gap-30">
                  <div className="roomGrid__content">
                    <div>
                      <div className="y-gap-8">
                        {props.room.facilities.map((facility, index) => (
                          <div className="d-flex items-center text-green-2">
                            <i className="icon-check text-12 mr-10"></i>
                            <div className="text-15">{facility}</div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* <div>
                      <div className="text-18 lh-15 fw-500">
                        IDR {props.room.price.toLocaleString()}
                      </div>
                    </div> */}

                    <div>
                      <p>{props.room.overview}</p>
                    </div>
                  </div>
                </div>

                <div>
                  <a 
                    href={"https://wa.me/6285176738324?text=Hi, I want to book " + props.room.title + " on " + props.accommodation.title}
                    className="button h-50 px-24 -dark-1 bg-blue-1 text-white mt-10"
                  >
                    Reserve <div className="icon-arrow-top-right ml-15"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default RoomDetail;
