import React, { Fragment } from "react";

function RegulationSingle(props) {
  return (
    <Fragment>
      <div className="col-12">
        <div className="text-15">{props.regulation}</div>
      </div>
    </Fragment>
  );
}

export default RegulationSingle;
