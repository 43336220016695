import React, { Fragment } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TourPackages from '../tour-packages.json';
import { useParams } from 'react-router-dom';
import Gallery from '../components/Gallery';
import ActivityCard from '../components/ActivityCard';
import FaqItem from '../components/FaqItem';
import ItineraryItem from '../components/ItineraryItem';
import TourPackageCard from '../components/TourPackageCard';

function TourPackageSingle() {
  const { slug } = useParams();

  const randomTourPackages = TourPackages.sort(() => Math.random() - 0.5);

  const tourPackage = TourPackages.filter(
    (tourPackage) => tourPackage.slug == slug
  )[0];

  console.log(slug, 'slug');
  console.log(tourPackage, 'tour package');

  return (
    <Fragment>
      <div className="header-margin"></div>
      <Header></Header>

      <section className="pt-40">
        <div className="container">
          <div className="row y-gap-15 justify-between items-end">
            <div className="col-auto">
              <h1 className="text-30 fw-600">{tourPackage.title}</h1>

              <div className="row x-gap-20 y-gap-20 items-center">
                <div className="col-auto">
                  <div className="d-flex items-center text-15 text-light-1">
                    {tourPackage.location}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-40">
        <div className="container">
          <div className="row y-gap-30">
            <div className="col-lg-12">
              <Gallery
                type="tour-package"
                slug={tourPackage.slug}
                image={tourPackage.image}
              />

              <h3 className="text-22 fw-500 mt-30">Tour snapshot</h3>

              <div className="row y-gap-30 justify-between pt-20">
                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <div className="text-15 lh-15">
                      Price per couple:
                      <br />{' '}
                      <strong>
                        IDR {tourPackage.price_per_pax.toLocaleString()}
                      </strong>
                    </div>
                  </div>
                </div>

                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <div className="text-15 lh-15">
                      Minimal pax:
                      <br /> <strong>{tourPackage.minimal_pax}</strong>
                    </div>
                  </div>
                </div>

                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <div className="text-15 lh-15">
                      Duration
                      <br /> <strong>{tourPackage.duration}</strong>
                    </div>
                  </div>
                </div>

                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <div className="text-15 lh-15">
                      Category
                      <br /> <strong>{tourPackage.category}</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-top-light mt-40 mb-40"></div>

              <div className="row x-gap-40 y-gap-40">
                <div className="col-12">
                  <h3 className="text-22 fw-500">Overview</h3>

                  <p className="text-dark-1 text-15 mt-20">
                    {tourPackage.overview}
                  </p>
                </div>
              </div>

              <div className="mt-40 border-top-light">
                <div className="row x-gap-40 y-gap-40 pt-40">
                  <div className="col-12">
                    <h3 className="text-22 fw-500">What's Included</h3>

                    <div className="row x-gap-40 y-gap-40 pt-20">
                      <div className="col-md-6">
                        {tourPackage.include.map((include, index) => (
                          <div className="text-dark-1 text-15" key={index}>
                            <i className="icon-check text-10 mr-10"></i>{' '}
                            {include}
                          </div>
                        ))}
                      </div>

                      <div className="col-md-6">
                        {tourPackage.exclude.map((exclude, index) => (
                          <div className="text-dark-1 text-15" key={index}>
                            <i className="icon-close text-green-2 text-10 mr-10"></i>{' '}
                            {exclude}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-40">
        <div className="container">
          <div className="pt-40 border-top-light">
            <div className="row x-gap-40 y-gap-40">
              <div className="col-auto">
                <h3 className="text-22 fw-500">Things to bring</h3>
              </div>
            </div>

            <div className="row x-gap-40 y-gap-40 justify-between pt-20">
              <div className="col-12">
                <ul className="list-disc">
                  {tourPackage.things_to_bring.map((things_to_bring, index) => (
                    <li key={index}>{things_to_bring}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-40 mb-40">
        <div className="border-top-light"></div>
      </div>

      <section>
        <div className="container">
          <h3 className="text-22 fw-500 mb-20">Itinerary</h3>

          <div className="row y-gap-30">
            <div>
              <div className="relative">
                <div className="border-test"></div>

                <div className="accordion -map row y-gap-20 js-accordion">
                  {tourPackage.itinerary.map((itinerary, index) => (
                    <ItineraryItem
                      key={index}
                      index={index}
                      itinerary={itinerary}
                      slug={tourPackage.slug}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container mt-40 mb-40">
        <div className="border-top-light"></div>
      </div>

      <div>
        <section className="pt-40">
          <div className="container">
            <div className="pt-40 border-top-light">
              <div className="row x-gap-40 y-gap-40">
                <div className="col-auto">
                  <h3 className="text-22 fw-500">Cancelation Policy</h3>
                </div>
              </div>

              <div className="row x-gap-40 y-gap-40 justify-between pt-20">
                <div className="col-12">
                  <h6 className="mb-5">1. Cancellation Periods:</h6>
                  <p className="mb-10">
                    Early Cancellation (More than 30 days before departure/any
                    activities is done):<br></br>
                    Full refund of the activity/tour package price, excluding
                    non-refundable deposits or fees that have been mentioned
                    earlier (If there's any)<br></br>
                  </p>
                  <p className="mb-10">
                    Standard Cancellation (15 to 30 days before departure):
                    <br></br>
                    50% refund of the activity/tour package price, excluding
                    non-refundable deposits or fees that have been mentioned
                    earlier (If there's any)<br></br>
                  </p>
                  <p className="mb-10">
                    Late Cancellation (Less than 15 days before departure):
                    <br></br>
                    No refund will be provided. Customers may be eligible for a
                    partial credit towards a future trip, subject to our
                    discretion.<br></br>
                  </p>

                  <h6 className="mb-5">2. Non-Refundable Deposits:</h6>
                  <p className="mb-10">
                    Certain components of the activity/tour package may require
                    non-refundable deposits (e.g., activity, accommodation,
                    special events, tour package). These deposits are
                    non-refundable under any circumstances.
                  </p>

                  <h6 className="mb-5">3. Refunds:</h6>
                  <p className="mb-10">
                    Refunds, if applicable, will be processed within 2-3
                    business days from the date of cancellation request.
                  </p>

                  <h6 className="mb-5">4. Booking Changes:</h6>
                  <p className="mb-10">
                    Customers can request changes to their booking (e.g., change
                    of travel/activivity/tour dates) up to 2 days before the
                    original date, subject to availability.
                  </p>

                  <h6 className="mb-5">5. Force Majeure:</h6>
                  <p className="mb-10">
                    In the event of unforeseen circumstances or "force majeure"
                    events (e.g., natural disasters, political instability),
                    Babali Tour reserves the right to modify, postpone, or
                    cancel the trip. In such cases, customers will be offered
                    alternative travel dates or a credit towards a future trip.
                  </p>

                  <h6 className="mb-5">6. Communication of Cancellation:</h6>
                  <p className="mb-10">
                    All cancellation requests must be submitted in writing via
                    email/Whatsapp to{' '}
                    <a
                      href="mailto:reservation@babalitour.com"
                      style={{ color: 'blue' }}
                    >
                      reservation@babalitour.com
                    </a>{' '}
                    /{' '}
                    <a
                      style={{ color: 'blue' }}
                      href="https://wa.me/6285176738324"
                    >
                      +62 851-7673-8324
                    </a>{' '}
                    . The cancellation date is considered the date the request
                    is received.
                  </p>

                  <h6 className="mb-5">7. Travel Insurance:</h6>
                  <p className="mb-10">
                    We strongly recommend that all customers purchase
                    comprehensive travel insurance to cover unexpected events,
                    including trip cancellations, interruptions, and
                    emergencies. (Please note that all of the activities that
                    you booked in Babali Tour including (ATV Quad Bike, Rafting,
                    Bikking, Mount Hikking) have included Insurance).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="container mt-40 mb-40">
        <div className="border-top-light"></div>
      </div>

      <section>
        <div className="container">
          <div className="row y-gap-20">
            <div className="col-lg-4">
              <h2 className="text-22 fw-500">
                FAQs about
                <br /> {tourPackage.title}
              </h2>
            </div>

            <div className="col-lg-8">
              <div className="accordion -simple row y-gap-20 js-accordion">
                {tourPackage.faq.map((faq, index) => (
                  <FaqItem key={index} faq={faq} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="mt-50 border-top-light"></div>
      </div>

      <section className="layout-pt-lg layout-pb-lg">
        <div className="container">
          <div className="row justify-between items-end">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Similar Experiences</h2>
              </div>
            </div>

            <div className="col-auto">
              <a
                href="/activities"
                className="button h-50 px-24 -blue-1 bg-blue-1-05 text-blue-1"
              >
                See All <div className="icon-arrow-top-right ml-15"></div>
              </a>
            </div>
          </div>

          <div className="row y-gap-30 pt-40 sm:pt-20">
            {randomTourPackages.slice(0, 3).map((tourPackage) => (
              <TourPackageCard key={tourPackage.id} tourPackage={tourPackage} />
            ))}
          </div>
        </div>
      </section>

      <div
        className="col-12"
        style={{ position: 'fixed', bottom: 0, zIndex: 1000 }}
      >
        <div className="d-flex justify-end">
          <div className="d-flex flex-column items-center sticky-bottom">
            <div className="px-5 py-5 rounded-4 border-light bg-white shadow-4 sticky-bottom">
              <div className="text-14 text-light-1">
                From{' '}
                <span className="text-20 fw-500 text-dark-1 ml-5">
                  IDR {tourPackage.price_per_pax.toLocaleString()}
                </span>
              </div>

              <div className="row y-gap-20">
                <div className="col-12">
                  <a
                    href={
                      'https://wa.me/6285176738324?text=Hi, I want to book ' +
                      tourPackage.title
                    }
                    className="button -dark-1 py-15 px-35 h-60 col-12 rounded-4 bg-blue-1 text-white"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </Fragment>
  );
}

export default TourPackageSingle;
