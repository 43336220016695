import React, { Fragment, useState } from "react";

function SearchBar(props) {
  const [search, setSearch] = useState('');

  const handleInputChange  = (event) => {
    const { name, value } = event.target;
    setSearch(value)
  };
  
  const handleSearch  = () => {
    props.onSearch('search', search)
  };

  return (
    <Fragment>
      <div className="sidebar__item -no-border">
        <div className="px-20 py-20 bg-light-2 rounded-4">
          <h5 className="text-18 fw-500 mb-10">Search</h5>
          <div className="row y-gap-20 pt-20">
            <div className="col-12">
              <input type="text" required className="bg-white py-15 px-15" 
                value={search}
                onChange={handleInputChange}
             />
            </div>

            <div className="col-12">
              <button onClick={handleSearch}
                className="button -dark-1 py-15 px-35 h-60 col-12 rounded-4 bg-blue-1 text-white">
                <i className="icon-search text-20 mr-10"></i>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SearchBar;
