import React, { Fragment } from "react";

function PlaceAroundSingle(props) {
  return (
    <Fragment>
      <div className="row y-gap-10 pt-10">
        <div className="col-12">
          <div className="row items-center justify-between">
            <div className="col-auto">
              <div className="text-15">{props.place_around.name}</div>
            </div>

            <div className="col-auto">
              <div className="text-15 text-right">{props.place_around.distance}</div>
            </div>
          </div>
          <div className="border-top-light"></div>
        </div>
      </div>
    </Fragment>
  );
}

export default PlaceAroundSingle;
