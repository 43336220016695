import React, { Fragment } from "react";

function FacilitySingle(props) {
  return (
    <Fragment>
      <li className="d-flex items-center">
        <i className="icon-check text-10 mr-20"></i>
        {props.facility}
      </li>
    </Fragment>
  );
}

export default FacilitySingle;
