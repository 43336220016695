import React, { Fragment, useState } from 'react';
import { ReactComponent as Spinner } from '../270-ring.svg';

function Footer() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  function Submit(e) {
    setInProgress(true);
    e.preventDefault();
    const formEle = document.querySelector('#form-subscribe');
    const formDatab = new FormData(formEle);
    fetch(
      'https://script.google.com/macros/s/AKfycbxj2n6rsL2PDjkamk3RmZe3eYIQC0Eh4CQD_9u0XM-Y_61U94PbB9mOFrowGf_AMSNS/exec',
      {
        method: 'POST',
        body: formDatab,
      }
    )
      .then((res) => res)
      .then((data) => {
        setIsSuccess(true);
        setInProgress(false);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Fragment>
      <footer className="footer -type-2 bg-blue-1 text-white">
        <div className="container">
          <div className="pt-60 pb-60">
            <div className="row y-gap-40 justify-between xl:justify-start">
              <div className="col-xl-4 col-lg-6">
                <img
                  className="footer-logo"
                  src="/assets/img/general/babali.svg"
                  alt="image"
                />

                <div className="row y-gap-30 justify-between pt-30">
                  <div className="col-sm-6">
                    <div className="text-14">Phone Number/WhatsApp</div>
                    <a
                      href="https://wa.me/6285176738324"
                      className="text-18 fw-500 mt-5"
                    >
                      +62 851-7673-8324
                    </a>
                  </div>

                  <div className="col-sm-5">
                    <div className="text-14">Need live support?</div>
                    <a
                      href="mailto:info@babalitour.com"
                      className="text-18 fw-500 mt-5"
                    >
                      info@babalitour.com
                    </a>
                  </div>
                </div>

                <div className="mt-60">
                  <h6 className="text-16 fw-500 mb-10">
                    Follow us on social media
                  </h6>

                  <div className="d-flex x-gap-20 items-center">
                    <a
                      href="https://www.facebook.com/profile.php?id=61555612914369&mibextid=kFxxJD"
                      aria-label="Facebook"
                    >
                      <i className="icon-facebook text-14"></i>
                    </a>
                    {/* <a href="#" aria-label="Twitter">
                      <i className="icon-twitter text-14"></i>
                    </a> */}
                    <a
                      href="https://www.instagram.com/babalitour?igsh=MWI2ZGhybGhoeGRkdw=="
                      aria-label="Instagram"
                    >
                      <i className="icon-instagram text-14"></i>
                    </a>
                    <a
                      href="https://www.tiktok.com/@babalitour?_t=8jTsSl1iG0H&_r=1"
                      aria-label="Tiktok"
                    >
                      <i className="fa-brands fa-tiktok"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="row y-gap-30">
                  <div className="col-12 pb-30">
                    <h5 className="text-16 fw-500 mb-15">Get Updates & More</h5>

                    <form
                      className="single-field relative d-flex justify-end items-center"
                      id="form-subscribe"
                      onSubmit={(e) => Submit(e)}
                    >
                      <input
                        className="bg-white rounded-8"
                        type="email"
                        placeholder="Your Email"
                        name="Email"
                      />
                      <button
                        className="absolute px-20 h-full text-15 fw-500 underline text-dark-1"
                        type="submit"
                      >
                        <span className={inProgress ? 'd-none' : ''}>
                          Subscribe
                        </span>{' '}
                        <Spinner className={!inProgress ? 'd-none' : ''} />{' '}
                      </button>
                    </form>
                    <p
                      className={
                        'text-white pt-10 ' + (!isSuccess ? 'd-none' : '')
                      }
                    >
                      Thank you for subscribing!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-20 border-top-white-15">
            <div className="row justify-between items-center y-gap-10">
              <div className="col-auto">
                <div className="row x-gap-30 y-gap-10">
                  <div className="col-auto">
                    <div className="text-14">
                      © 2024 Babali Tour All rights reserved.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

export default Footer;
