import React, { Fragment } from "react";

function FormCheckbox(props) {
  return (
    <Fragment>
      <div className="row y-gap-10 items-center justify-between">
        <div className="col-auto">
          <div className="d-flex items-center">
            <div className="form-checkbox ">
              <input type="checkbox" name={props.type} id={props.value} onClick={() => props.onClicked(props.type, props.value)}/>
              <div className="form-checkbox__mark">
                <div className="form-checkbox__icon icon-check"></div>
              </div>
            </div>

            <label htmlFor={props.value} className="text-15 ml-10">{props.text}</label>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FormCheckbox;
