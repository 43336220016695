import React, { Fragment } from "react";

function Gallery(props) {
  return (
    <Fragment>
      <div className="galleryGrid -type-1 pt-30">
        <div className="galleryGrid__item relative d-flex">
          <img
            src={"/assets/img/"+props.type+"/"+props.slug+"/"+props.image[0]}
            alt="image"
            className="rounded-4"
          />
        </div>

        <div className="galleryGrid__item">
          <img
            src={"/assets/img/"+props.type+"/"+props.slug+"/"+props.image[1]}
            alt="image"
            className="rounded-4"
          />
        </div>

        <div className="galleryGrid__item relative d-flex">
          <img
            src={"/assets/img/"+props.type+"/"+props.slug+"/"+props.image[2]}
            alt="image"
            className="rounded-4"
          />
        </div>

        <div className="galleryGrid__item">
          <img
            src={"/assets/img/"+props.type+"/"+props.slug+"/"+props.image[3]}
            alt="image"
            className="rounded-4"
          />
        </div>

        <div className="galleryGrid__item relative d-flex">
          <img
            src={"/assets/img/"+props.type+"/"+props.slug+"/"+props.image[4]}
            alt="image"
            className="rounded-4"
          />

          <div className="absolute px-10 py-10 col-12 h-full d-flex justify-end items-end">
            <a
              href={"/assets/img/"+props.type+"/"+props.slug+"/"+props.image[0]}
              className="button -blue-1 px-24 py-15 bg-white text-dark-1 js-gallery"
              data-gallery={props.slug}
            >
              See All Photos
            </a>
            {props.image.slice(1, 100).map((image, index) => (
              <a
                key={index}
                href={"/assets/img/"+props.type+"/"+props.slug+"/"+image}
                className="js-gallery"
                data-gallery={props.slug}
              ></a>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Gallery;
