import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <Fragment>
      <header
        data-add-bg="-header-5-sticky"
        className="header -type-5 js-header"
        data-x="header"
        data-x-toggle="is-menu-opened"
      >
        <div className="header__container container">
          <div className="row justify-between items-center">
            <div className="col-auto">
              <div className="d-flex items-center">
                <div className="mr-20">
                  <button
                    className="d-flex items-center icon-menu text-dark-1 text-20"
                    aria-label="Desktop Menu"
                    data-x-click="desktopMenu"
                  ></button>
                </div>

                <a
                  href="/"
                  className="header-logo mr-20"
                  data-x="header-logo"
                  data-x-toggle="is-logo-dark"
                >
                  <img src="/assets/img/general/babali.svg" alt="logo icon" />
                </a>

                <div
                  className="header-menu "
                  data-x="mobile-menu"
                  data-x-toggle="is-menu-active"
                >
                  <div className="mobile-overlay"></div>

                  <div className="header-menu__content">
                    <div className="mobile-bg js-mobile-bg"></div>

                    <div className="menu js-navList">
                      <ul className="menu__nav">
                        <li>
                          <a href="/" className="ml-50 mr-20">
                            Home
                          </a>
                        </li>
                        <li>
                          <a href="/accommodations" className="ml-20 mr-20">
                            Accommodations
                          </a>
                        </li>
                        <li>
                          <a href="/activities" className="ml-20 mr-20">
                            Activites
                          </a>
                        </li>
                        <li>
                          <a href="/tour-packages" className="ml-20 mr-20">
                            Tour Packages
                          </a>
                        </li>
                        {/* <li>
                          <a href="/blogs" className="ml-20 mr-20">
                            Blogs
                          </a>
                        </li> */}
                        <li>
                          <a href="/contact" className="ml-20 mr-20">
                            Contact
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="mobile-footer px-20 py-20 border-top-light js-mobile-footer"></div>
                  </div>
                </div>

                <div
                  className="desktopMenu js-desktopMenu"
                  data-x="desktopMenu"
                  data-x-toggle="is-menu-active"
                >
                  <div className="desktopMenu-overlay"></div>

                  <div className="desktopMenu__content">
                    <div className="mobile-bg js-mobile-bg"></div>

                    <div className="px-30 py-20 sm:px-20 sm:py-10 border-bottom-light">
                      <div className="row justify-between items-center">
                        <div className="col-auto">
                          <div className="text-20 fw-500">Main Menu</div>
                        </div>

                        <div className="col-auto">
                          <button
                            className="icon-close text-15"
                            aria-label="Desktop Menu"
                            data-x-click="desktopMenu"
                          ></button>
                        </div>
                      </div>
                    </div>

                    <div className="h-full px-30 py-30 sm:px-0 sm:py-10">
                      <div className="menu js-navList">
                        <ul className="menu__nav  -is-active">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="/accommodations">Accommodations</a>
                          </li>
                          <li>
                            <a href="/activities">Activites</a>
                          </li>
                          <li>
                            <a href="/tour-packages">Tour Packages</a>
                          </li>
                          {/* <li>
                            <a href="/blogs">Blogs</a>
                          </li> */}
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="d-flex items-center">
                <div className="header__buttons d-flex items-center is-menu-opened-hide">
                  <a
                    href="https://wa.me/6285176738324"
                    className="button h-50 px-30 fw-400 text-14 -blue-1 bg-blue-1 text-white sm:d-none"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
}

export default Header;
