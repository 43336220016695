import React, { Fragment } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AccommodationCard from '../components/AccommodationCard';
import FaqItem from '../components/FaqItem';
import Gallery from '../components/Gallery';
import RoomDetail from '../components/RoomDetail';
import PlaceAroundSingle from '../components/PlaceAroundSingle';
import RegulationSingle from '../components/RegulationSingle';
import FacilitySingle from '../components/FacilitySingle';
import Accommodations from '../accommodations.json';
import { useParams } from 'react-router-dom';

function AccommodationSingle() {
  const { slug } = useParams();

  const randomAccommodations = Accommodations.sort(() => Math.random() - 0.5);

  const accommodation = Accommodations.filter(
    (accommodation) => accommodation.slug == slug
  )[0];

  return (
    <Fragment>
      <div className="header-margin"></div>
      <Header></Header>

      <section className="pt-40">
        <div className="container">
          <div className="row y-gap-20 justify-between items-end">
            <div className="col-auto">
              <div className="row x-gap-20  items-center">
                <div className="col-auto">
                  <h1 className="text-30 sm:text-25 fw-600">
                    {accommodation.title}
                  </h1>
                </div>
              </div>

              <div className="row x-gap-20 y-gap-20 items-center">
                <div className="col-auto">
                  <div className="d-flex items-center text-15 text-light-1">
                    {accommodation.location}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="row x-gap-15 y-gap-15 items-center">
                <div className="col-auto">
                  <a 
                    href={"https://wa.me/6285176738324?text=Hi, I want to book " + accommodation.title}
                    className="button h-50 px-24 -dark-1 bg-blue-1 text-white"
                  >
                    Select Room{' '}
                    <div className="icon-arrow-top-right ml-15"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Gallery
            type="accommodation"
            slug={accommodation.slug}
            image={accommodation.image}
          />
        </div>
      </section>

      <section className="pt-30">
        <div className="container">
          <div className="row y-gap-30">
            <div className="col-xl-8">
              <div className="row y-gap-40">
                <div id="overview" className="col-12">
                  <h3 className="text-22 fw-500 pt-40 border-top-light">
                    Overview
                  </h3>
                  <p className="text-dark-1 text-15 mt-20">
                    {accommodation.overview}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="ml-50 lg:ml-0">
                <div className="px-30 py-30 border-light rounded-4 mt-30">
                  <div className="flex-center ratio ratio-15:9 mb-15 js-lazy">
                    <a
                      className="button py-15 px-24 -blue-1 bg-white text-dark-1 absolute"
                      target="blank"
                      href={accommodation.maps}
                    >
                      <i className="icon-location text-22 mr-10"></i>
                      Show on map
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="inclusion"></div>
      <section className="mt-40">
        <div className="container">
          <div className="row x-gap-40 y-gap-40">
            <div className="col-12">
              <h3 className="text-22 fw-500">
                Inclusion of {accommodation.title}
              </h3>

              <div className="row x-gap-40 y-gap-40 pt-20">
                <div className="col-12">
                  <div className="row y-gap-30">
                    <div className="col-12">
                      {accommodation.inclusions.map((inclusion, index) => (
                        <FacilitySingle key={index} facility={inclusion} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="rooms" className="pt-30">
        <div className="container">
          <div className="row pb-20">
            <div className="col-auto">
              <h3 className="text-22 fw-500">Available Rooms</h3>
            </div>
          </div>

          {accommodation.rooms.map((room, index) => (
            <RoomDetail key={index} room={room} slug={accommodation.slug} accommodation={accommodation} />
          ))}
        </div>
      </section>

      <div className="container">
        <div className="border-top-light mt-30"></div>
      </div>

      <div id="facilities"></div>
      <section className="mt-40">
        <div className="container">
          <div className="row x-gap-40 y-gap-40">
            <div className="col-12">
              <h3 className="text-22 fw-500">
                Facilities of {accommodation.title}
              </h3>

              <div className="row x-gap-40 y-gap-40 pt-20">
                <div className="col-xl-4">
                  <div className="row y-gap-30">
                    <div className="col-12">
                      {accommodation.facilities.map((facility, index) => (
                        <FacilitySingle key={index} facility={facility} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-40">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="text-22 fw-500">Hotel surroundings</h3>
            </div>
          </div>

          <div className="row x-gap-50 y-gap-30 pt-20">
            <div className="col-lg-4 col-md-6">
              {accommodation.place_around.map((place_around, index) => (
                <PlaceAroundSingle key={index} place_around={place_around} />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="pt-40">
        <div className="container">
          <div className="pt-40 border-top-light">
            <div className="row">
              <div className="col-12">
                <h3 className="text-22 fw-500">Some helpful facts</h3>
              </div>
            </div>

            <div className="row x-gap-50 y-gap-10 pt-20">
              {accommodation.regulations.map((regulation, index) => (
                <RegulationSingle key={index} regulation={regulation} />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section id="faq" className="pt-40 layout-pb-md">
        <div className="container">
          <div className="pt-40 border-top-light">
            <div className="row y-gap-20">
              <div className="col-lg-4">
                <h2 className="text-22 fw-500">
                  FAQs about
                  <br /> {accommodation.title}
                </h2>
              </div>

              <div className="col-lg-8">
                <div className="accordion -simple row y-gap-20 js-accordion">
                  {accommodation.faq.map((faq, index) => (
                    <FaqItem key={index} faq={faq} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">
                  Popular accommodations similar to {accommodation.title}
                </h2>
              </div>
            </div>
          </div>

          <div className="row y-gap-30 pt-40 sm:pt-20">
            {randomAccommodations.slice(0, 3).map((accommodation, index) => (
              <AccommodationCard key={index} accommodation={accommodation} />
            ))}
          </div>
        </div>
      </section>

      <Footer></Footer>
    </Fragment>
  );
}

export default AccommodationSingle;
