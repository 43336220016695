import React, { Fragment } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Blog from '../blog.json';
import Accommodations from '../accommodations.json';
import Activities from '../activities.json';
import TourPackages from '../tour-packages.json';
import AccommodationCard from '../components/AccommodationCard';
import ActivityCard from '../components/ActivityCard';
import TourPackageCard from '../components/TourPackageCard';

function Index() {
  const latestBlog = Blog.slice().reverse();

  const accommodations = Accommodations.sort(() => Math.random() - 0.5);
  const activities = Activities.sort(() => Math.random() - 0.5);
  const tourPackages = TourPackages.sort(() => Math.random() - 0.5);

  return (
    <Fragment>
      <Header></Header>

      <section className="masthead -type-5">
        <div className="masthead__bg">
          <img src="/assets/img/masthead/5/bg.svg" alt="image" />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-9">
              <h1 className="text-50 lg:text-40 md:text-25">
                Discover Bali,{' '}
                <span className="text-blue-1 relative">
                  The Babali Way!{' '}
                  <span className="-line">
                    <img src="/assets/img/general/line.png" alt="image" />
                  </span>
                </span>
              </h1>

              <p className="mt-20">
                Experience the various exciting tour and travel packages and
                Make hotel reservations, find
                <br className="lg:d-none" /> vacation packages, search hotels
                and activities
              </p>
            </div>
          </div>
        </div>

        <div className="masthead__image">
          <img src="/assets/img/masthead/5/1.jpg" alt="image" />
        </div>
      </section>

      <section className="layout-pt-lg layout-pb-md">
        <div className="container">
          <div className="row y-gap-20 justify-between items-end">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">
                  Recommended Accommodations
                </h2>
              </div>
            </div>

            <div className="col-auto">
              <a
                href="/accommodations"
                className="button -md -blue-1 bg-blue-1-05 text-blue-1"
              >
                More Accommodations{' '}
                <div className="icon-arrow-top-right ml-15"></div>
              </a>
            </div>
          </div>

          <div className="row y-gap-30 mt-30">
            {accommodations.slice(0, 4).map((accommodation, index) => (
              <AccommodationCard
                key={index}
                accommodation={accommodation}
                width="3"
              />
            ))}
          </div>
        </div>
      </section>

      <section className="layout-pt-lg layout-pb-md">
        <div className="container">
          <div className="row y-gap-20 justify-between items-end">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Recommended Activities</h2>
              </div>
            </div>

            <div className="col-auto">
              <a
                href="/activities"
                className="button -md -blue-1 bg-blue-1-05 text-blue-1"
              >
                More Activities
                <div className="icon-arrow-top-right ml-15"></div>
              </a>
            </div>
          </div>

          <div className="row y-gap-30 mt-30">
            {activities.slice(0, 4).map((accommodation, index) => (
              <ActivityCard key={index} activity={accommodation} width="3" />
            ))}
          </div>
        </div>
      </section>

      <section className="layout-pt-lg layout-pb-md">
        <div className="container">
          <div className="row y-gap-20 justify-between items-end">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">
                  Recommended Tour Packages
                </h2>
              </div>
            </div>

            <div className="col-auto">
              <a
                href="/tour-packages"
                className="button -md -blue-1 bg-blue-1-05 text-blue-1"
              >
                More Tour Packages
                <div className="icon-arrow-top-right ml-15"></div>
              </a>
            </div>
          </div>

          <div className="row y-gap-30 mt-30">
            {tourPackages.slice(0, 4).map((accommodation, index) => (
              <TourPackageCard
                key={index}
                tourPackage={accommodation}
                width="3"
              />
            ))}
          </div>
        </div>
      </section>

      {/* <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row y-gap-15 justify-center text-center">
            <div className="col-xl-3 col-sm-6">
              <div className="text-40 lh-13 text-blue-1 fw-600">4,958</div>
              <div className="text-14 lh-14 text-light-1 mt-5">
                Destinations
              </div>
            </div>

            <div className="col-xl-3 col-sm-6">
              <div className="text-40 lh-13 text-blue-1 fw-600">2,869</div>
              <div className="text-14 lh-14 text-light-1 mt-5">
                Total Properties
              </div>
            </div>

            <div className="col-xl-3 col-sm-6">
              <div className="text-40 lh-13 text-blue-1 fw-600">2M</div>
              <div className="text-14 lh-14 text-light-1 mt-5">
                Happy customers
              </div>
            </div>

            <div className="col-xl-3 col-sm-6">
              <div className="text-40 lh-13 text-blue-1 fw-600">574,974</div>
              <div className="text-14 lh-14 text-light-1 mt-5">
                Our Volunteers
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-bg layout-pt-lg md:pt-0 md:pb-60 sm:pb-40 layout-pb-lg bg-blue-1-05">
        <div className="section-bg__item -right -image col-5 md:mb-60 sm:mb-40">
          <img src="/assets/img/backgrounds/5.png" alt="image" />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-7">
              <h2 className="text-30 fw-600">Why Choose Us</h2>
              <p className="mt-5">
                These popular destinations have a lot to offer
              </p>

              <div className="row y-gap-30 pt-60 md:pt-40">
                <div className="col-12">
                  <div className="d-flex pr-30">
                    <img
                      className="size-50"
                      src="/assets/img/featureIcons/1/1.svg"
                      alt="image"
                    />

                    <div className="ml-15">
                      <h4 className="text-18 fw-500">Best Price Guarantee</h4>
                      <p className="text-15 mt-10">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex pr-30">
                    <img
                      className="size-50"
                      src="/assets/img/featureIcons/1/2.svg"
                      alt="image"
                    />

                    <div className="ml-15">
                      <h4 className="text-18 fw-500">Easy & Quick Booking</h4>
                      <p className="text-15 mt-10">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex pr-30">
                    <img
                      className="size-50"
                      src="/assets/img/featureIcons/1/3.svg"
                      alt="image"
                    />

                    <div className="ml-15">
                      <h4 className="text-18 fw-500">Customer Care 24/7</h4>
                      <p className="text-15 mt-10">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-bg layout-pt-lg layout-pb-md">
        <div className="section-bg__item col-12">
          <img src="/assets/img/backgrounds/testimonials/bg.png" alt="image" />
        </div>

        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Customer Reviews</h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  Interdum et malesuada fames ac ante ipsum
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-center pt-60 md:pt-30">
            <div className="col-xl-5 col-lg-8 col-md-11">
              <div
                className="overflow-hidden js-section-slider"
                data-slider-cols="base-1"
                data-gap="30"
                data-pagination="js-testimonials-pag"
              >
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="testimonials -type-2 text-center">
                      <div className="testimonials__image mb-24 md:mb-20">
                        <img
                          src="/assets/img/testimonials/2/quote.svg"
                          alt="quote"
                        />
                        <img
                          src="/assets/img/testimonials/2/1.png"
                          alt="quote"
                        />
                      </div>

                      <h4 className="text-16 fw-500 text-blue-1">
                        Hotel Equatorial Melaka
                      </h4>
                      <div className="fw-500 text-dark-1 mt-20">
                        "Our family was traveling via bullet train between
                        cities in Japan with our luggage - the location for this
                        hotel made that so easy. Agoda price was fantastic. "
                      </div>

                      <div className="testimonials__author mt-40">
                        <h5 className="text-15 lh-14 fw-500">
                          Brooklyn Simmons
                        </h5>
                        <div className="text-14 text-light-1 mt-5">
                          Web Developer
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonials -type-2 text-center">
                      <div className="testimonials__image mb-24 md:mb-20">
                        <img
                          src="/assets/img/testimonials/2/quote.svg"
                          alt="quote"
                        />
                        <img
                          src="/assets/img/testimonials/2/2.png"
                          alt="quote"
                        />
                      </div>

                      <h4 className="text-16 fw-500 text-blue-1">
                        Hotel Equatorial Melaka
                      </h4>
                      <div className="fw-500 text-dark-1 mt-20">
                        "Our family was traveling via bullet train between
                        cities in Japan with our luggage - the location for this
                        hotel made that so easy. Agoda price was fantastic. "
                      </div>

                      <div className="testimonials__author mt-40">
                        <h5 className="text-15 lh-14 fw-500">
                          Brooklyn Simmons
                        </h5>
                        <div className="text-14 text-light-1 mt-5">
                          Web Developer
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonials -type-2 text-center">
                      <div className="testimonials__image mb-24 md:mb-20">
                        <img
                          src="/assets/img/testimonials/2/quote.svg"
                          alt="quote"
                        />
                        <img
                          src="/assets/img/testimonials/2/3.png"
                          alt="quote"
                        />
                      </div>

                      <h4 className="text-16 fw-500 text-blue-1">
                        Hotel Equatorial Melaka
                      </h4>
                      <div className="fw-500 text-dark-1 mt-20">
                        "Our family was traveling via bullet train between
                        cities in Japan with our luggage - the location for this
                        hotel made that so easy. Agoda price was fantastic. "
                      </div>

                      <div className="testimonials__author mt-40">
                        <h5 className="text-15 lh-14 fw-500">
                          Brooklyn Simmons
                        </h5>
                        <div className="text-14 text-light-1 mt-5">
                          Web Developer
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonials -type-2 text-center">
                      <div className="testimonials__image mb-24 md:mb-20">
                        <img
                          src="/assets/img/testimonials/2/quote.svg"
                          alt="quote"
                        />
                        <img
                          src="/assets/img/testimonials/2/4.png"
                          alt="quote"
                        />
                      </div>

                      <h4 className="text-16 fw-500 text-blue-1">
                        Hotel Equatorial Melaka
                      </h4>
                      <div className="fw-500 text-dark-1 mt-20">
                        "Our family was traveling via bullet train between
                        cities in Japan with our luggage - the location for this
                        hotel made that so easy. Agoda price was fantastic. "
                      </div>

                      <div className="testimonials__author mt-40">
                        <h5 className="text-15 lh-14 fw-500">
                          Brooklyn Simmons
                        </h5>
                        <div className="text-14 text-light-1 mt-5">
                          Web Developer
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="swiper-slide">
                    <div className="testimonials -type-2 text-center">
                      <div className="testimonials__image mb-24 md:mb-20">
                        <img
                          src="/assets/img/testimonials/2/quote.svg"
                          alt="quote"
                        />
                        <img
                          src="/assets/img/testimonials/2/5.png"
                          alt="quote"
                        />
                      </div>

                      <h4 className="text-16 fw-500 text-blue-1">
                        Hotel Equatorial Melaka
                      </h4>
                      <div className="fw-500 text-dark-1 mt-20">
                        "Our family was traveling via bullet train between
                        cities in Japan with our luggage - the location for this
                        hotel made that so easy. Agoda price was fantastic. "
                      </div>

                      <div className="testimonials__author mt-40">
                        <h5 className="text-15 lh-14 fw-500">
                          Brooklyn Simmons
                        </h5>
                        <div className="text-14 text-light-1 mt-5">
                          Web Developer
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex x-gap-15 items-center justify-center pt-40 sm:pt-20">
                  <div className="col-auto">
                    <button className="d-flex items-center text-24 arrow-left-hover js-prev">
                      <i className="icon icon-arrow-left"></i>
                    </button>
                  </div>

                  <div className="col-auto">
                    <div className="pagination -dots text-border js-testimonials-pag"></div>
                  </div>

                  <div className="col-auto">
                    <button className="d-flex items-center text-24 arrow-right-hover js-next">
                      <i className="icon icon-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="layout-pt-md layout-pb-md">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">
                  Get inspiration for your next trip
                </h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  Interdum et malesuada fames
                </p>
              </div>
            </div>
          </div>

          <div className="row y-gap-30 pt-40 sm:pt-20">
            {latestBlog.slice(0, 3).map((post) => (
              <div className="col-lg-4 col-sm-6" key={post.id}>
                <a
                  href={"/blog/" + post.id}
                  className="blogCard -type-1 d-block "
                >
                  <div className="blogCard__image">
                    <div className="ratio ratio-4:3 rounded-4 rounded-8">
                      <img
                        className="img-ratio js-lazy"
                        src="#"
                        data-src={"/assets/img/blog/" + post.image}
                        alt="image"
                      />
                    </div>
                  </div>

                  <div className="mt-20">
                    <h4 className="text-dark-1 text-18 fw-500">{post.title}</h4>
                    <div className="text-light-1 text-15 lh-14 mt-5">
                      {post.date}
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      <Footer></Footer>
    </Fragment>
  );
}

export default Index;
