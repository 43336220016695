import React, { Fragment, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Blog from "../blog.json";

function Blogs() {
  const latestBlog = Blog.slice().reverse();

  const [count, setCount] = useState(9);

  const loadMore = () => {
    setCount(count + 9);
  };

  return (
    <Fragment>
      <div className="header-margin"></div>
      <Header></Header>

      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Travel articles</h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  Lorem ipsum is placeholder text commonly used in site.
                </p>
              </div>
            </div>
          </div>

          <div className="tabs -pills-3 pt-30 js-tabs">
            <div className="pt-30">
              <div className="">
                <div className="row y-gap-30">
                  {latestBlog.slice(0, count).map((post) => (
                    <div className="col-lg-4 col-sm-6" key={post.id}>
                      <a
                        href={"/blog/" + post.id}
                        className="blogCard -type-1 d-block "
                      >
                        <div className="blogCard__image">
                          <div className="ratio ratio-4:3 rounded-8">
                            <img
                              className="img-ratio"
                              src={"/assets/img/blog/" + post.image}
                              alt="image"
                            />
                          </div>
                        </div>

                        <div className="pt-20">
                          <h4 className="text-dark-1 text-18 fw-500">
                            {post.title}
                          </h4>
                          <div className="text-light-1 text-15 lh-14 mt-5">
                            {post.date}
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {latestBlog.length > count ? (
            <button
              className="button h-50 px-30 fw-400 text-14 -blue-1 bg-blue-1 text-white sm:d-none m-auto mt-50"
              onClick={loadMore}
            >
              Load More
            </button>
          ) : (
            ""
          )}
        </div>
      </section>

      <Footer></Footer>
    </Fragment>
  );
}

export default Blogs;
