import React, { Fragment } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Blog from "../blog.json";
import { useParams } from "react-router-dom";

function BlogSingle() {
  const { id } = useParams();

  const randomBlogs = Blog.sort(() => Math.random() - 0.5);

  const blog = Blog.filter((blog) => blog.id == id)[0];

  return (
    <Fragment>
      <div className="header-margin"></div>
      <Header></Header>

      <section className="layout-pt-md">
        <div className="container">
          <div className="row y-gap-40 justify-center text-center">
            <div className="col-auto">
              <div className="text-15 fw-500 text-blue-1 mb-8">
                {blog.category}
              </div>
              <h1 className="text-30 fw-600">{blog.title}</h1>
              <div className="text-15 text-light-1 mt-10">{blog.date}</div>
            </div>

            <div className="col-12">
              <img
                src={"/assets/img/blog/" + blog.image}
                alt="image"
                className="col-12 rounded-8"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="layout-pt-md layout-pb-md">
        <div className="container">
          <div className="row y-gap-30 justify-center">
            <div className="col-xl-8 col-lg-10">{blog.body}</div>
          </div>
        </div>
      </section>

      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Related content</h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  Interdum et malesuada fames
                </p>
              </div>
            </div>
          </div>

          <div className="row y-gap-30 pt-40">
            {randomBlogs.slice(0, 4).map((post) => (
              <div className="col-lg-3 col-sm-6">
                <a
                  href=""
                  className="blogCard -type-2 d-block bg-white rounded-4 shadow-4"
                >
                  <div className="blogCard__image">
                    <div className="ratio ratio-1:1 rounded-4">
                      <img
                        className="img-ratio js-lazy"
                        src="#"
                        data-src={"/assets/img/blog/" + post.image}
                        alt="image"
                      />
                    </div>
                  </div>

                  <div className="px-20 py-20">
                    <h4 className="text-dark-1 text-16 lh-18 fw-500">
                      {post.title}
                    </h4>
                    <div className="text-light-1 text-15 lh-14 mt-10">
                      {post.date}
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer></Footer>
    </Fragment>
  );
}

export default BlogSingle;
