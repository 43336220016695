import React, { Fragment } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Activities from '../activities.json';
import { useParams } from 'react-router-dom';
import Gallery from '../components/Gallery';
import ActivityCard from '../components/ActivityCard';
import FaqItem from '../components/FaqItem';
import ItineraryItem from '../components/ItineraryItem';

function ActivitySingle() {
  const { slug } = useParams();

  const randomActivities = Activities.sort(() => Math.random() - 0.5);

  const activity = Activities.filter((activity) => activity.slug == slug)[0];

  console.log(slug);
  console.log(activity);

  return (
    <Fragment>
      <div className="header-margin"></div>
      <Header></Header>

      <section className="pt-40">
        <div className="container">
          <div className="row y-gap-15 justify-between items-end">
            <div className="col-auto">
              <h1 className="text-30 fw-600">{activity.title}</h1>

              <div className="row x-gap-20 y-gap-20 items-center">
                <div className="col-auto">
                  <div className="d-flex items-center text-15 text-light-1">
                    {activity.location}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-40">
        <div className="container">
          <div className="row y-gap-30">
            <div className="col-lg-12">
              <Gallery
                type="activity"
                slug={activity.slug}
                image={activity.image}
              />

              <h3 className="text-22 fw-500 mt-30">Activity snapshot</h3>

              <div className="row y-gap-30 justify-between pt-20">
                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <div className="text-15 lh-15">
                      Price:
                      <br />{' '}
                      <strong>
                        IDR {activity.price_per_pax.toLocaleString()}
                      </strong>
                    </div>
                  </div>
                </div>

                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <div className="text-15 lh-15">
                      Minimal pax:
                      <br /> <strong>{activity.minimal_pax}</strong>
                    </div>
                  </div>
                </div>

                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <div className="text-15 lh-15">
                      Duration
                      <br /> <strong>{activity.duration}</strong>
                    </div>
                  </div>
                </div>

                <div className="col-md-auto col-6">
                  <div className="d-flex">
                    <div className="text-15 lh-15">
                      Category
                      <br /> <strong>{activity.category}</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-top-light mt-40 mb-40"></div>

              <div className="row x-gap-40 y-gap-40">
                <div className="col-12">
                  <h3 className="text-22 fw-500">Overview</h3>

                  <p className="text-dark-1 text-15 mt-20">
                    {activity.overview}
                  </p>
                </div>
              </div>

              <div className="mt-40 border-top-light">
                <div className="row x-gap-40 y-gap-40 pt-40">
                  <div className="col-12">
                    <h3 className="text-22 fw-500">What's Included</h3>

                    <div className="row x-gap-40 y-gap-40 pt-20">
                      <div className="col-md-6">
                        {activity.include.map((include, index) => (
                          <div className="text-dark-1 text-15" key={index}>
                            <i className="icon-check text-10 mr-10"></i>{' '}
                            {include}
                          </div>
                        ))}
                      </div>

                      <div className="col-md-6">
                        {activity.exclude.map((exclude, index) => (
                          <div className="text-dark-1 text-15" key={index}>
                            <i className="icon-close text-green-2 text-10 mr-10"></i>{' '}
                            {exclude}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {activity.things_to_bring && activity.things_to_bring?.length > 0 ? (
        <div>
          <section className="pt-40">
            <div className="container">
              <div className="pt-40 border-top-light">
                <div className="row x-gap-40 y-gap-40">
                  <div className="col-auto">
                    <h3 className="text-22 fw-500">Things to bring</h3>
                  </div>
                </div>

                <div className="row x-gap-40 y-gap-40 justify-between pt-20">
                  <div className="col-lg-4 col-md-6">
                    <ul className="list-disc">
                      {activity.things_to_bring.map(
                        (things_to_bring, index) => (
                          <li key={index}>{things_to_bring}</li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div></div>
      )}

      {activity.itinerary && activity.itinerary?.length > 0 ? (
        <div>
          <div className="container mt-40 mb-40">
            <div className="border-top-light"></div>
          </div>

          <section>
            <div className="container">
              <h3 className="text-22 fw-500 mb-20">Itinerary</h3>

              <div className="row y-gap-30">
                <div>
                  <div className="relative">
                    <div className="border-test"></div>

                    <div className="accordion -map row y-gap-20 js-accordion">
                      {activity.itinerary.map((itinerary, index) => (
                        <ItineraryItem
                          key={index}
                          itinerary={itinerary}
                          activity_id={activity.id}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div></div>
      )}

      {activity.faq && activity.faq?.length > 0 ? (
        <div>
          <div className="container mt-40 mb-40">
            <div className="border-top-light"></div>
          </div>

          <section>
            <div className="container">
              <div className="row y-gap-20">
                <div className="col-lg-4">
                  <h2 className="text-22 fw-500">
                    FAQs about
                    <br /> {activity.title}
                  </h2>
                </div>

                <div className="col-lg-8">
                  <div className="accordion -simple row y-gap-20 js-accordion">
                    {activity.faq.map((faq, index) => (
                      <FaqItem key={index} faq={faq} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div></div>
      )}

      {activity.cancelation_policy &&
      activity.cancelation_policy?.length > 0 ? (
        <div>
          <section className="pt-40">
            <div className="container">
              <div className="pt-40 border-top-light">
                <div className="row x-gap-40 y-gap-40">
                  <div className="col-auto">
                    <h3 className="text-22 fw-500">Cancelation Policy</h3>
                  </div>
                </div>

                <div className="row x-gap-40 y-gap-40 justify-between pt-20">
                  <div className="col-12">
                    <ul className="list-disc">
                      {activity.cancelation_policy.map(
                        (cancelation_policy, index) => (
                          <div key={index} className="mb-3">
                            <h6>{cancelation_policy.title}</h6>
                            <p>{cancelation_policy.description}</p>
                          </div>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div></div>
      )}

      <div className="container">
        <div className="mt-50 border-top-light"></div>
      </div>

      <section className="layout-pt-lg layout-pb-lg">
        <div className="container">
          <div className="row justify-between items-end">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Similar Experiences</h2>
              </div>
            </div>

            <div className="col-auto">
              <a
                href="/activities"
                className="button h-50 px-24 -blue-1 bg-blue-1-05 text-blue-1"
              >
                See All <div className="icon-arrow-top-right ml-15"></div>
              </a>
            </div>
          </div>

          <div className="row y-gap-30 pt-40 sm:pt-20">
            {randomActivities.slice(0, 3).map((activity) => (
              <ActivityCard key={activity.id} activity={activity} />
            ))}
          </div>
        </div>
      </section>

      <div
        className="col-12"
        style={{ position: 'fixed', bottom: 0, zIndex: 1000 }}
      >
        <div className="d-flex justify-end">
          <div className="w-1000 d-flex flex-column items-center sticky-bottom">
            <div className="px-5 py-5 rounded-4 border-light bg-white shadow-4 sticky-bottom">
              <div className="text-14 text-light-1">
                From{' '}
                <span className="text-20 fw-500 text-dark-1 ml-5">
                  IDR {activity.price_per_pax.toLocaleString()}
                </span>
              </div>

              <div className="row y-gap-20">
                <div className="w-1000 col-12">
                  <a
                    href={
                      'https://wa.me/6285176738324?text=Hi, I want to book ' +
                      activity.title
                    }
                    className="button -dark-1 py-15 px-35 h-60 col-12 rounded-4 bg-blue-1 text-white"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </Fragment>
  );
}

export default ActivitySingle;
