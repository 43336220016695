import React, { Fragment } from "react";

function ItineraryItem(props) {
  return (
    <Fragment>
      { props.itinerary.link ? <div className="col-12">
        <div className="">
          <div className="d-flex">
            <div className="accordion__icon size-40 flex-center bg-blue-2 text-blue-1 rounded-full">
              <div className="text-14 fw-500">{props.index + 1}</div>
            </div>

            <div className="ml-20">
              <div className="text-16 lh-15 fw-500">{props.itinerary.title}</div>
              <div className="text-14 lh-15 text-light-1 mt-5">
                Stop: {props.itinerary.duration}
              </div>

              {/* <div className="accordion__content">
                <div className="pt-15 pb-15">
                  <img
                    src={"/assets/img/activity/"+props.activity_id+"/"+props.itinerary.image}
                    alt="image"
                    className="rounded-4 mt-15"
                  />
                  <div className="text-14 lh-17 mt-15">
                    {props.itinerary.description}
                  </div>
                </div>
              </div> */}

              <div>
                <a href={props.itinerary.link}
                  target="_blank"
                  className="d-block lh-15 text-14 text-blue-1 underline fw-500 mt-5"
                >
                  See details & photo
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> :

      <div className="col-12">
        <div className="accordion__item ">
          <div className="d-flex">
            <div className="accordion__icon size-40 flex-center bg-blue-2 text-blue-1 rounded-full">
              <div className="text-14 fw-500">{props.index + 1}</div>
            </div>

            <div className="ml-20">
              <div className="text-16 lh-15 fw-500">{props.itinerary.title}</div>
              <div className="text-14 lh-15 text-light-1 mt-5">
                Stop: {props.itinerary.duration}
              </div>

              <div className="accordion__content">
                <div className="pt-15 pb-15">
                  <img
                    src={"/assets/img/tour-package/"+props.slug+"/"+props.itinerary.image}
                    alt="image"
                    className="rounded-4 mt-15"
                  />
                  <div className="text-14 lh-17 mt-15">
                    {props.itinerary.description}
                  </div>
                </div>
              </div>

              <div className="accordion__button">
                <button
                  data-open-change-title="See less"
                  className="d-block lh-15 text-14 text-blue-1 underline fw-500 mt-5"
                >
                  See details & photo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> }
    </Fragment>
  );
}

export default ItineraryItem;
