import React, { Fragment } from "react";

function FaqItem(props) {
  return (
    <Fragment>
      <div className="col-12">
        <div className="accordion__item px-20 py-20 border-light rounded-4">
          <div className="accordion__button d-flex items-center">
            <div className="accordion__icon size-40 flex-center bg-light-2 rounded-full mr-20">
              <i className="icon-plus"></i>
              <i className="icon-minus"></i>
            </div>

            <div className="button text-dark-1">
              {props.faq.question}
            </div>
          </div>

          <div className="accordion__content">
            <div className="pt-20 pl-60">
              <p className="text-15">
                {props.faq.answer}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default FaqItem;
