import React, { Fragment, useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import FormCheckbox from "../components/FormCheckbox";
import SearchBar from "../components/SearchBar";
import ActivityCard from "../components/ActivityCard";
import Activities from "../activities.json";

let filters = []
function Activity() {
  const [latestActivities, setLatestActivities] = useState(Activities.slice().reverse());

  const allActivities  = Activities.slice().reverse();

  var rawLocation = allActivities.map((item) => {
    return item.location;
  })

  var location = [...new Set(rawLocation)];

  var rawCategory = allActivities.map((item) => {
    return item.category;
  })

  var category = [...new Set(rawCategory)];

  const [count, setCount] = useState(9);

  const loadMore = () => {
    setCount(count + 9);
  };
  
  const FilterCategory = (type, value) => {
      var isExist = filters.some(item => type === item.type && value === item.value)

      if(isExist){
        filters = filters.filter(item => value != item.value)
      }

      if(type == 'search'){
        filters = filters.filter(item => type != item.type)
      }
      
      if(!isExist || type == 'search'){
        filters.push({"type": type, "value": value})
      }

      var tempActivitiesLocation = []
      var tempActivitiesCategory = []
      var tempActivitiesSearch = []

      filters.map((filter) => {
        if(filter.type == 'location'){
          tempActivitiesLocation = [...tempActivitiesLocation, ...allActivities.filter(activity => filter.value == activity.location)]
        }else if(filter.type == 'category'){
          tempActivitiesCategory = [...tempActivitiesCategory, ...allActivities.filter(activity => filter.value == activity.category)]
        }else if(filter.type == 'search'){
          tempActivitiesSearch = [...tempActivitiesSearch, ...allActivities.filter(activity => activity.title.toLocaleLowerCase().includes(filter.value.toLocaleLowerCase()))]
        }
      })
      console.log(tempActivitiesSearch)
      console.log(filters)

      if(tempActivitiesLocation.length > 0 && tempActivitiesCategory.length > 0 && tempActivitiesSearch.length > 0){
        var rawData1 = tempActivitiesLocation.filter(element => tempActivitiesCategory.includes(element));
        var rawData = rawData1.filter(element => tempActivitiesSearch.includes(element));
        setLatestActivities(rawData)
      }else if(tempActivitiesLocation.length > 0 && tempActivitiesCategory.length > 0){
        var rawData = tempActivitiesLocation.filter(element => tempActivitiesCategory.includes(element));
        setLatestActivities(rawData)
      }else if(tempActivitiesCategory.length > 0 && tempActivitiesSearch.length > 0){
        var rawData = tempActivitiesCategory.filter(element => tempActivitiesSearch.includes(element));
        setLatestActivities(rawData)
      }else if(tempActivitiesSearch.length > 0 && tempActivitiesLocation.length > 0){
        var rawData = tempActivitiesSearch.filter(element => tempActivitiesLocation.includes(element));
        setLatestActivities(rawData)
      }else if(tempActivitiesLocation.length > 0){
        var rawData = tempActivitiesLocation
        setLatestActivities(rawData)
      }else if(tempActivitiesCategory.length > 0){
        var rawData = tempActivitiesCategory
        setLatestActivities(rawData)
      }else if(tempActivitiesSearch.length > 0){
        var rawData = tempActivitiesSearch
        setLatestActivities(rawData)
      }else if(filters.length == 0){
        setLatestActivities(allActivities)
      }else{
        setLatestActivities([])
      }
  };

  return (
    <Fragment>
      <div className="header-margin"></div>
      <Header></Header>

      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row y-gap-30">
            <div className="col-xl-3 col-lg-4 lg:d-none">
              <aside className="sidebar y-gap-40">
                <SearchBar onSearch={FilterCategory}/>

                <div className="sidebar__item -no-border">
                  <h5 className="text-18 fw-500 mb-10">Category</h5>
                  <div className="sidebar-checkbox">
                    {category.map((cat, index) => (
                      <FormCheckbox type="category" value={cat} text={cat} key={index} onClicked={FilterCategory}/>
                    ))}
                  </div>
                </div>

                <div className="sidebar__item">
                  <h5 className="text-18 fw-500 mb-10">Location</h5>
                  <div className="sidebar-checkbox">
                    {location.map((loc, index) => (
                      <FormCheckbox type="location" value={loc} text={loc} key={index} onClicked={FilterCategory} />
                    ))}
                  </div>
                </div>
              </aside>
            </div>

            <div className="col-xl-9 col-lg-8">
              <div className="row y-gap-10 items-center justify-between">
                <div className="col-auto">
                  <div className="text-18"></div>
                </div>

                <div className="col-auto">
                  <div className="row x-gap-20 y-gap-20">
                    <div className="col-auto d-none lg:d-block">
                      <button
                        data-x-click="filterPopup"
                        className="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1"
                      >
                        <i className="icon-up-down text-14 mr-10"></i>
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="filterPopup bg-white"
                data-x="filterPopup"
                data-x-toggle="-is-active"
              >
                <aside className="sidebar -mobile-filter">
                  <div data-x-click="filterPopup" className="-icon-close">
                    <i className="icon-close"></i>
                  </div>

                  <SearchBar onSearch={FilterCategory}/>

                  <div className="sidebar__item -no-border">
                    <h5 className="text-18 fw-500 mb-10">Category</h5>
                    <div className="sidebar-checkbox">
                      {category.map((cat, index) => (
                        <FormCheckbox type="category" value={cat} text={cat} key={index} onClicked={FilterCategory}/>
                      ))}
                    </div>
                  </div>

                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">Duration</h5>
                    <div className="sidebar-checkbox">
                      {location.map((loc, index) => (
                        <FormCheckbox type="location" value={loc} text={loc} key={index} onClicked={FilterCategory} />
                      ))}
                    </div>
                  </div>
                </aside>
              </div>

              <div className="border-top-light mt-30 mb-30"></div>

              <div className="row y-gap-30">
                {latestActivities.slice(0, count).map((activity) => (
                  <ActivityCard key={activity.id} activity={activity} />
                ))}

                {latestActivities.length == 0 && 
                  <p>No Activities Founds</p>
                }
              </div>

              {latestActivities.length > count ? (
                <button
                  className="button h-50 px-30 fw-400 text-14 -blue-1 bg-blue-1 text-white sm:d-none m-auto mt-50"
                  onClick={loadMore}
                >
                  Load More
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </Fragment>
  );
}

export default Activity;
