import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Index from './pages/Index';
import Activity from './pages/Activity';
import Blogs from './pages/Blogs';
import Contact from './pages/Contact';
import BlogSingle from './pages/BlogSingle';
import ActivitySingle from './pages/ActivitySingle';
import Accommodation from './pages/Accommodation';
import AccommodationSingle from './pages/AccommodationSingle';
import TourPackage from './pages/TourPackage';
import TourPackageSingle from './pages/TourPackageSingle';

function App() {
  return (
    <main>
    <Router>
      <Routes>
        <Route path='/' element={<Index/>} />
        <Route path='/activities' element={<Activity/>} />
        <Route path="/activity/:slug" element={<ActivitySingle/>} />
        <Route path='/blogs' element={<Blogs/>} />
        <Route path="/blog/:id" element={<BlogSingle/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/accommodations' element={<Accommodation/>} />
        <Route path="/accommodation/:slug" element={<AccommodationSingle/>} />
        <Route path='/tour-packages' element={<TourPackage/>} />
        <Route path="/tour-package/:slug" element={<TourPackageSingle/>} />
      </Routes>
    </Router>
    </main>
  );
}

export default App;
