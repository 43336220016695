import React, { Fragment } from "react";

function AccommodationCard(props) {
  // var cheapestRoom = Math.min(...props.accommodation.rooms.map(item => item.price));
  
  return (
    <Fragment>
      <div className={(props.width == "3" ? "col-lg-3" : "col-lg-4") + " col-sm-6"}>
        <a href={"/accommodation/" + props.accommodation.slug} className="hotelsCard -type-1 ">
          <div className="hotelsCard__image">
            <div className="cardImage ratio ratio-1:1 rounded-4">
              <div className="cardImage__content">
                <img
                  className="rounded-4 col-12"
                  src={"/assets/img/accommodation/"+props.accommodation.slug+"/"+props.accommodation.image[0]}
                  alt="image"
                />
              </div>
            </div>
          </div>

          <div className="hotelsCard__content mt-10">
            <h6 className="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
              <span>{props.accommodation.title}</span>
            </h6>

            <p className="text-light-1 lh-14 text-14 mt-5">
              {props.accommodation.location}
            </p>

            {/* <div className="mt-5">
              <div className="fw-500">
                Starting from <span className="text-blue-1">IDR {cheapestRoom.toLocaleString()}</span>
              </div>
            </div> */}
          </div>
        </a>
      </div>
    </Fragment>
  );
}

export default AccommodationCard;
