import React, { Fragment } from "react";

function TourPackageCard(props) {
  return (
    <Fragment>
      <div className={(props.width == "3" ? "col-lg-3" : "col-lg-4") + " col-sm-6"}>
        <a href={"/tour-package/" + props.tourPackage.slug} className="tourCard -type-1 rounded-4 ">
          <div className="tourCard__image">
            <div className="cardImage ratio ratio-1:1">
              <div className="cardImage__content">
                <img
                  className="rounded-4 col-12"
                  src={"/assets/img/tour-package/"+props.tourPackage.slug+"/"+props.tourPackage.image[0]}
                  alt="image"
                />
              </div>
            </div>
          </div>

          <div className="tourCard__content mt-10">
            <div className="d-flex items-center lh-14 mb-5">
              <div className="text-14 text-light-1">{props.tourPackage.duration}</div>
              <div className="size-3 bg-light-1 rounded-full ml-10 mr-10"></div>
              <div className="text-14 text-light-1">{props.tourPackage.category}</div>
            </div>

            <h6 className="tourCard__title text-dark-1 text-18 lh-16 fw-500">
              <span>
                {props.tourPackage.title}
              </span>
            </h6>

            <p className="text-light-1 lh-14 text-14 mt-5">
              {props.tourPackage.location}
            </p>

            {/* <div className="row justify-between items-center pt-15">
              <div className="col-auto">
                <div className="text-14 text-light-1">
                  From
                  <span className="text-16 fw-500 text-dark-1"> IDR {props.tourPackage.price_per_pax.toLocaleString()}</span>
                </div>
              </div>
            </div> */}
          </div>
        </a>
      </div>
    </Fragment>
  );
}

export default TourPackageCard;
