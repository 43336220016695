import React, { Fragment, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SearchBar from "../components/SearchBar";
import FormCheckbox from "../components/FormCheckbox";
import AccommodationCard from "../components/AccommodationCard";
import Accommodations from "../accommodations.json";

let filters = []
function Accommodation() {
  const [latestAccommodations, setLatestAccommodations] = useState(Accommodations.slice().reverse());
  const allAccommodations  = Accommodations.slice().reverse();

  var rawLocation = allAccommodations.map((item) => {
    return item.location;
  })

  var location = [...new Set(rawLocation)];

  const [count, setCount] = useState(9);

  const loadMore = () => {
    setCount(count + 9);
  };

  const FilterCategory = (type, value) => {
    var isExist = filters.some(item => type === item.type && value === item.value)

    if(isExist){
      filters = filters.filter(item => value != item.value)
    }

    if(type == 'search'){
      filters = filters.filter(item => type != item.type)
    }
    
    if(!isExist || type == 'search'){
      filters.push({"type": type, "value": value})
    }

    var tempActivitiesLocation = []
    var tempActivitiesCategory = []
    var tempActivitiesSearch = []

    filters.map((filter) => {
      if(filter.type == 'location'){
        tempActivitiesLocation = [...tempActivitiesLocation, ...allAccommodations.filter(activity => filter.value == activity.location)]
      }else if(filter.type == 'category'){
        tempActivitiesCategory = [...tempActivitiesCategory, ...allAccommodations.filter(activity => filter.value == activity.category)]
      }else if(filter.type == 'search'){
        tempActivitiesSearch = [...tempActivitiesSearch, ...allAccommodations.filter(activity => activity.title.toLocaleLowerCase().includes(filter.value.toLocaleLowerCase()))]
      }
    })
    console.log(tempActivitiesSearch)
    console.log(filters)

    if(tempActivitiesLocation.length > 0 && tempActivitiesCategory.length > 0 && tempActivitiesSearch.length > 0){
      var rawData1 = tempActivitiesLocation.filter(element => tempActivitiesCategory.includes(element));
      var rawData = rawData1.filter(element => tempActivitiesSearch.includes(element));
      setLatestAccommodations(rawData)
    }else if(tempActivitiesLocation.length > 0 && tempActivitiesCategory.length > 0){
      var rawData = tempActivitiesLocation.filter(element => tempActivitiesCategory.includes(element));
      setLatestAccommodations(rawData)
    }else if(tempActivitiesCategory.length > 0 && tempActivitiesSearch.length > 0){
      var rawData = tempActivitiesCategory.filter(element => tempActivitiesSearch.includes(element));
      setLatestAccommodations(rawData)
    }else if(tempActivitiesSearch.length > 0 && tempActivitiesLocation.length > 0){
      var rawData = tempActivitiesSearch.filter(element => tempActivitiesLocation.includes(element));
      setLatestAccommodations(rawData)
    }else if(tempActivitiesLocation.length > 0){
      var rawData = tempActivitiesLocation
      setLatestAccommodations(rawData)
    }else if(tempActivitiesCategory.length > 0){
      var rawData = tempActivitiesCategory
      setLatestAccommodations(rawData)
    }else if(tempActivitiesSearch.length > 0){
      var rawData = tempActivitiesSearch
      setLatestAccommodations(rawData)
    }else if(filters.length == 0){
      setLatestAccommodations(allAccommodations)
    }else{
      setLatestAccommodations([])
    }
  };

  return (
    <Fragment>
      <div className="header-margin"></div>
      <Header></Header>

      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row y-gap-30">
            <div className="col-xl-3 col-lg-4 lg:d-none">
              <aside className="sidebar y-gap-40">
                <SearchBar onSearch={FilterCategory}/>

                <div className="sidebar__item">
                  <h5 className="text-18 fw-500 mb-10">Location</h5>
                  <div className="sidebar-checkbox">
                    {location.map((loc, index) => (
                      <FormCheckbox type="location" value={loc} text={loc} key={index} onClicked={FilterCategory} />
                    ))}
                  </div>
                </div>
              </aside>
            </div>

            <div className="col-xl-9 col-lg-8">
              <div className="row y-gap-10 items-center justify-between">
                <div className="col-auto">
                  <div className="text-18">
                  </div>
                </div>

                <div className="col-auto">
                  <div className="row x-gap-20 y-gap-20">
                    <div className="col-auto d-none lg:d-block">
                      <button
                        data-x-click="filterPopup"
                        className="button -blue-1 h-40 px-20 rounded-100 bg-blue-1-05 text-15 text-blue-1"
                      >
                        <i className="icon-up-down text-14 mr-10"></i>
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="filterPopup bg-white"
                data-x="filterPopup"
                data-x-toggle="-is-active"
              >
                <aside className="sidebar -mobile-filter">
                  <div data-x-click="filterPopup" className="-icon-close">
                    <i className="icon-close"></i>
                  </div>

                  <div className="sidebar__item">
                    <SearchBar onSearch={FilterCategory}/>
                  </div>

                  <div className="sidebar__item">
                    <h5 className="text-18 fw-500 mb-10">Location</h5>
                    <div className="sidebar-checkbox">
                      {location.map((loc, index) => (
                        <FormCheckbox type="location" value={loc} text={loc} key={index} onClicked={FilterCategory} />
                      ))}
                    </div>
                  </div>
          
                </aside>
              </div>

              <div className="border-top-light mt-30 mb-30"></div>

              <div className="row y-gap-30">
                {latestAccommodations.slice(0, count).map((accommodation, index) => (
                  <AccommodationCard key={index} accommodation={accommodation} />
                ))}

                {latestAccommodations.length == 0 && 
                  <p>No Accommodations Founds</p>
                }
              </div>

              {latestAccommodations.length > count ? (
                <button
                  className="button h-50 px-30 fw-400 text-14 -blue-1 bg-blue-1 text-white sm:d-none m-auto mt-50"
                  onClick={loadMore}
                >
                  Load More
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </Fragment>
  );
}

export default Accommodation;
