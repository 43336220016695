import React, { Fragment, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ReactComponent as Spinner } from "../270-ring.svg";

function Contact() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  function Submit(e) {
    setInProgress(true);
    e.preventDefault();
    const formEle = document.querySelector("#form-contact");
    const formDatab = new FormData(formEle);
    fetch(
      "https://script.google.com/macros/s/AKfycbwRJ7Bck6KJXqH10Qd_VQnW00F6wa-T_g4EmHzAOrXIMmd7myI5l2ObXBf9lJrlvRfUCg/exec",
      {
        method: "POST",
        body: formDatab,
      }
    )
      .then((res) => res)
      .then((data) => {
        setIsSuccess(true);
        setInProgress(false);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Fragment>
      <div className="header-margin"></div>
      <Header></Header>

        <img src="/assets/img/contact.webp" alt="image" style={{width: '100%'}}/>

      <section>
        <div className="relative container">
          <div className="row justify-end">
            <div className="col-xl-5 col-lg-7">
              <div className="map-form px-40 pt-40 pb-50 lg:px-30 lg:py-30 md:px-24 md:py-24 bg-white rounded-4 shadow-4">
                <div className="text-22 fw-500">Send a message</div>

                <form
                  className="row y-gap-20 pt-20 form"
                  id="form-contact"
                  onSubmit={(e) => Submit(e)}
                >
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="text" name="Name" required />
                      <label className="lh-1 text-16 text-light-1">
                        Full Name
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="email" name="Email" required />
                      <label className="lh-1 text-16 text-light-1">Email</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="text" name="WhatsApp" required />
                      <label className="lh-1 text-16 text-light-1">
                        WhatsApp Number
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input ">
                      <input type="text" name="Subject" required />
                      <label className="lh-1 text-16 text-light-1">
                        Subject
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-input ">
                      <textarea required name="Messages" rows="4"></textarea>
                      <label className="lh-1 text-16 text-light-1">
                        Your Messages
                      </label>
                    </div>
                  </div>
                  <div className="col-auto">
                    <button
                      href="#"
                      type="submit"
                      className="button px-24 h-50 -dark-1 bg-blue-1 text-white"
                    >
                      <span className={inProgress ? "d-none" : ""}>
                        Send a Messsage
                      </span>{" "}
                      <Spinner
                        style={{ fill: "white" }}
                        className={!inProgress ? "d-none" : ""}
                      />
                    </button>

                    <p
                      className={
                        "text-black pt-10 " + (!isSuccess ? "d-none" : "")
                      }
                    >
                      Thank you for reaching out!
                    </p>
                    <p
                      className={
                        "text-black pt-10 " + (!isSuccess ? "d-none" : "")
                      }
                    >
                      Your message has been received, and we appreciate your
                      interest in contacting us. We'll get back to you as soon
                      as possible to address your inquiry or request.
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row x-gap-80 y-gap-20 justify-between">
            <div className="col-12">
              <div className="text-30 sm:text-24 fw-600">Contact Us</div>
            </div>

            <div className="col-lg-3">
              <div className="text-14 text-light-1">Address</div>
              <div className="text-18 fw-500 mt-10">
                Gang Bima no 7 Buruan Blahbatuh Gianyar Bali Indonesia.
              </div>
            </div>

            <div className="col-auto">
              <div className="text-14 text-light-1">Phone Number/WhatsApp</div>
              <a href="https://wa.me/6285176738324" className="text-18 fw-500 mt-10">+62 851-7673-8324</a>
            </div>

            <div className="col-auto">
              <div className="text-14 text-light-1">Need live support?</div>
              <a href="mailto:info@babalitour.com" className="text-18 fw-500 mt-10">info@babalitour.com</a>
            </div>

            <div className="col-auto">
              <div className="text-14 text-light-1">
                Follow us on social media
              </div>
              <div className="d-flex x-gap-20 items-center mt-10">
              <a
                      href="https://www.facebook.com/profile.php?id=61555612914369&mibextid=kFxxJD"
                      aria-label="Facebook"
                    >
                      <i className="icon-facebook text-14"></i>
                    </a>
                    {/* <a href="#" aria-label="Twitter">
                      <i className="icon-twitter text-14"></i>
                    </a> */}
                    <a
                      href="https://www.instagram.com/babalitour?igsh=MWI2ZGhybGhoeGRkdw=="
                      aria-label="Instagram"
                    >
                      <i className="icon-instagram text-14"></i>
                    </a>
                    <a
                      href="https://www.tiktok.com/@babalitour?_t=8jTsSl1iG0H&_r=1"
                      aria-label="Tiktok"
                    >
                      <i className="fa-brands fa-tiktok"></i>
                    </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="layout-pt-lg layout-pb-lg bg-blue-2">
        <div className="container">
          <div className="row justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Why Choose Us</h2>
                <p className=" sectionTitle__text mt-5 sm:mt-0">
                  These popular destinations have a lot to offer
                </p>
              </div>
            </div>
          </div>

          <div className="row y-gap-40 justify-between pt-50">
            <div className="col-lg-3 col-sm-6">
              <div className="featureIcon -type-1 ">
                <div className="d-flex justify-center">
                  <img
                    src="#"
                    data-src="/assets/img/featureIcons/1/1.svg"
                    alt="image"
                    className="js-lazy"
                  />
                </div>

                <div className="text-center mt-30">
                  <h4 className="text-18 fw-500">Best Price Guarantee</h4>
                  <p className="text-15 mt-10">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="featureIcon -type-1 ">
                <div className="d-flex justify-center">
                  <img
                    src="#"
                    data-src="/assets/img/featureIcons/1/2.svg"
                    alt="image"
                    className="js-lazy"
                  />
                </div>

                <div className="text-center mt-30">
                  <h4 className="text-18 fw-500">Easy & Quick Booking</h4>
                  <p className="text-15 mt-10">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="featureIcon -type-1 ">
                <div className="d-flex justify-center">
                  <img
                    src="#"
                    data-src="/assets/img/featureIcons/1/3.svg"
                    alt="image"
                    className="js-lazy"
                  />
                </div>

                <div className="text-center mt-30">
                  <h4 className="text-18 fw-500">Customer Care 24/7</h4>
                  <p className="text-15 mt-10">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer></Footer>
    </Fragment>
  );
}

export default Contact;
